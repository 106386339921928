import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Flex, Box } from 'components/Layout'
import LocalImage from 'components/LocalImage'

import imageHeader from 'static/images/analytics/header/header_picture.webp'
import imageHeaderDonut from 'static/images/analytics/header/donut.webp'

const StyledImageHeader = styled(LocalImage)`
  position: absolute;
`

const StyledImageHeaderDonut = styled(StyledImageHeader)`
  filter: drop-shadow(0px 7.2022px 8.00245px rgba(14, 28, 56, 0.07));
  backdrop-filter: blur(9.60293px);

  ${({ coefficient }) => `  
  bottom: ${coefficient * 10}px;
  right: ${coefficient * 55}px;
  width: ${coefficient * 186}px;
  height: ${coefficient * 193.25}px`};
  animation: analytics-image-header-donut 8s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1s infinite both;
  @keyframes analytics-image-header-donut {
    0% {
      transform: translateY(0);
    }
    16.6666666667% {
      transform: scale(1.12) translateY(-16px);
    }
    33.3333333333% {
      transform: scale(1.12) translateY(-16px);
    }
    50.0000000001% {
      transform: scale(1.24) translateY(-32px);
    }
    66.6666666668% {
      transform: scale(1.24) translateY(-32px);
    }
    83.3333333335% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(0px);
    }
  }
`

const HeaderImageAnimation = () => {
  // this is a coefficient of the image. Real size 500px, but on the site it is 400px, so the coefficient would be 400/500
  const [coefficient, setCoefficient] = useState(1)
  const imageWrapperRef = useRef(null)

  const countCoefficient = () => {
    if (imageWrapperRef && imageWrapperRef.current) {
      const { current: { clientWidth, firstChild: { naturalWidth } = {} } = {} } = imageWrapperRef
      const newCoefficient = (clientWidth * 2) / naturalWidth
      if (!Number.isNaN(newCoefficient) && newCoefficient > 0) {
        setCoefficient(newCoefficient)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', countCoefficient)

    return () => {
      window.removeEventListener('resize', countCoefficient)
    }
  }, [])

  return (
    <Flex width="100%">
      <Flex maxWidth="537px" mx="auto" pb={{ mobile: 'l', desktop: 0 }}>
        <Box width="100%" position="relative" ref={imageWrapperRef}>
          <LocalImage
            src={imageHeader}
            width="100%"
            mx="auto"
            alt="Social media Reporting and Metrics that matter"
            onLoad={() => {
              countCoefficient()
            }}
          />

          <StyledImageHeaderDonut src={imageHeaderDonut} coefficient={coefficient} />
        </Box>
      </Flex>
    </Flex>
  )
}

export default HeaderImageAnimation
